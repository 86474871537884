import axios from "axios";
import { BaseResponse } from "@/api/api";
import { useMainStore } from "@/store";

//创建一个axios实例
export const $request = axios.create({
  //配置请求根路径
  baseURL: "/sapi",
  //请求的超时时间
  timeout: 20000,
});

export const $get = async (url: string, params: any) => {
  const { data } = await $request.get(url, { params: params });
  return data;
};
export const $getWithAuth = async (url: string, params: any) => {
  const auth = useMainStore().token;
  if (auth == undefined) {
    return {
      msg: "Permission denied",
    };
  }
  const { data } = await $request.get(url, {
    params: params,
    headers: { "Content-Type": "application/json", Authorization: auth },
  });
  return data;
};

//封装一个post
export const $post = async (url: string, params: any) => {
  const { data } = await $request.post(url, params, {
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const $postWithAuth = async (url: string, params: any) => {
  const auth = useMainStore().token;
  if (auth == undefined) {
    return {
      msg: "Permission denied",
    };
  }
  const { data } = await $request.post(url, params, {
    headers: { "Content-Type": "application/json", Authorization: auth },
  });
  return data;
};
