import { createApp } from "vue";
import Antd from "ant-design-vue";
import VueI18n from "./locales/index";
import "animate.css";
import App from "./App.vue";
//导入ant-design
import "ant-design-vue/dist/antd.css";
import router from "./router";
// 引入less
import "../static/reset.less";
// eslint-disable-next-line no-undef
// Vue.use(less);
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import * as echarts from "echarts/core";

import axios from "axios";
// import Vue3Lottie from "vue3-lottie";
// import "vue3-lottie/dist/style.css";
import "@lottiefiles/lottie-player";

import VueGridLayout from "vue-grid-layout";

axios.get("/my-echarts/dark.project.json").then((res) => {
  //读取文件内容，并转化为Json对
  //   const obj = JSON.parse(res.data);
  echarts.registerTheme("dark", res.data);
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
// app.use(Vue3Lottie, { name: "Vue3Lottie" });
app.use(Antd);
app.use(router);
app.use(pinia);
app.use(VueI18n);
app.use(VueGridLayout);

app.mount("#app");
